var PreviewCalendar = function (callback) {
  this.run = function (embed, ref) {
    this.ref = ref

    console.log(embed)

    this.loadCalendar(
      window,
      document,
      'script',
      'https://cal.bookingflix.com/vendor/embed.js',
      btoa(JSON.stringify(embed))
    )
  }

  this.loadCalendar = function (a, b, c, d, e, f, g) {
    f = b.createElement(c)
    g = this.ref
    f.async = 1
    f.src = d
    g.parentNode.replaceChild(f, g, b)
    /* eslint-disable */
    f.onload = function () {
      new get_cal(29).build(f, e)
      callback(f, e)
    }
    /* eslint-enable */
  }
}

export { PreviewCalendar }
