<template>
  <div>
    <div ref="calendar"><flixLoader /></div>
  </div>
</template>
<script>
import { PreviewCalendar } from '@/components/assistent/previewCalendar.js'
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {
    getLoadCalendar() {
      new PreviewCalendar(function (f, e) {}).run(
        {
          data: {
            load: {
              user: this.$route.params.user,
              ID: this.$route.params.id,
              demo: false,
              lang: 'de'
            }
          },
          saveSettings: 'https://cal.bookingflix.com/vendor/save/de.js'
        },
        this.$refs.calendar
      )
    }
  },
  mounted() {
    this.getLoadCalendar()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
